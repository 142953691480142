$divider-border: 1px solid #D8D8D8;

.map-sidebar {

  width: $sidebar-width;
  @include calc(height , "100vh - #{$navbar-height}");
  right: -$sidebar-width;
  min-height: 85vh;
  // overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  background-color: #ffffff;
  border-left: $divider-border;
  @include transition(right .35s ease);
  box-shadow: $fat-shadow;
  font-size: 14px;

  .panel-body {
    margin: 30px;
    padding: 0px;
    position: relative;
    // @include calc(height , "100vh - 50px - #{$navbar-height}");
  }

  &.revealed {
    right: 0;
  }

  .metadata {
    display: none;
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: $divider-border;

    &.revealed {
      display: block;
    }

    .title-container {
      display: flex;
    }

    .title-divider {
      border-top: $divider-border;
      flex-grow: 1;
    }

    .title {
      margin-top: -11px;
      padding: 0px 15px;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
    }

    .primary-attribute {
      text-align: center;
      font-weight: 100;
      margin-bottom: 10px;
      .label {
        color: $primary-grey-color;
        text-transform: uppercase;
        font-size: 14px;
        margin: 12px 0px 0px;
        margin-bottom: 5px;
      }
      .value {
        font-size: 36px;
      }
    }

    .divider {
      position: absolute;
      left: 107px;
      top: 10%;
      height: 52%;
      border-left: $component-border;
    }

    .attributes {
      ul {
        list-style-type: none;
        margin: 0px;
        padding: 0px;
      }

      li {
        color: $primary-grey-color;
        font-style: italic;
        font-weight: 100;
        text-align: center;
        margin-bottom: 5px;
      }

      .label {
        color: $primary-black-color;
        font-style: normal;
        font-weight: 700;
      }
    }
  }

  .chart-panel {
    display: none;
    &.revealed {
      display: block;
    }
  }

  .date-range-picker {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: $primary-grey-color;
    font-weight: 100;
    margin-bottom: 20px;

    .select-wrapper {
      // border: 1px solid #ccc;
      // border-radius: 3px;
      width: 100px;
      overflow: hidden;
      padding-bottom: 3px;

      select {
        color: $primary-black-color;
        font-weight: 700;
        margin-left: 10px;
        padding: 5px 8px;
        width: 100%;
        border: none;
        box-shadow: none;
        background: transparent;
        background-image: none;
        -webkit-appearance: none;

        &:focus {
          outline: none;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

    .icon {
      color: $primary-black-color;
      font-size: 10px;
      margin-bottom: 5px;
    }

  }

  .action-buttons {
    display: flex;
    justify-content: flex-end;

    button {
      font-size: 14px;
      margin-left: 10px;
    }

    #water-data-library {
      display: none;
      &.revealed {
        display: block;
      }
    }
  }

  .help-links {
    margin-top: 25px;
    text-align: center;
    font-style: italic;

    a {
      text-decoration: underline;
      color: $primary-grey-color;
      display: block;
      padding-bottom: 10px;
      &:hover {
        color: $primary-black-color;
        cursor: pointer;
      }
    }

  }

}
