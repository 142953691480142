// Globals
$font-stack: Calibri, Helvetica, Arial, sans-serif;
$primary-black-color: #2D2D2D;
$primary-grey-color: #808080; //#A1A1A1;
$medium-grey-color: #EFEFEF;
$light-grey-color: #F9F9F9;
$accent-color: #40D9AC;
$chart-color-dark-blue: #304894;  // dark blue
$chart-color-light-blue: #6FD1F6;  // light blue
$chart-color-red: #C74855;  // red
$chart-color-orange: #FC7242;   // orange
$chart-color-purple: #6954D3; // purple
$chart-color-green: #1C9973;  // green
$chart-color-yellow: #F7B633;  // yellow
$chart-color-medium-blue: #51A5DE;  // medium blue
$navbar-height: 50px;
$sidebar-width: 500px;
$fat-shadow: 0 1px 70px rgba(0, 0, 0, 0.15);
$component-border: 1px solid rgba(50,50,50,0.25);

// Mixins
@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin calc($property, $expression) {
  #{$property}: -webkit-calc(#{$expression});
  #{$property}: calc(#{$expression});
}

.invisible {
  display: none;
}

.visible {
  display: block;
}

.view {
  width: 100%;
  margin-top: $navbar-height;
  background-color: $light-grey-color;
}
