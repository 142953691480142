.navbar {
  background-color: #ffffff;
  border-bottom: 1px solid #ffffff;
  width: 100%;
  @include transition(width .35s ease);

  &.map-view {
    border-bottom: $component-border;
  }

  .calcite-title-main,
  .flow-interface-logo,
  .nav-link {
    cursor: pointer;
  }

  .flow-interface-logo {
    max-width: 110px;
    height: 80%;
    margin: 0 20px;
  }

  .calcite-title-main {
    color: $primary-black-color;
    font-size: 20px;
  }

  .nav-icon {
    font-size: 11px;
    line-height: 1;
    margin-left: 11px;
    margin-bottom: 1px;
    border-radius: 3px;
    padding: 1px 4px;

    &.beta {
      color: #49a942;
      border: 1px solid #49a942;
    }

    &.dev-mode {
      display: none;
      color: #6954D3;
      border: 1px solid #6954D3;
      margin-left: 11px;
    }
  }

  [class*="esri-icon-"] {
    font-weight: bold !important;
  }

  .navbar-nav {
    padding-right: 5px;
    li {
      margin-left: 10px;
      display: flex;
    }
  }

  .nav-link {
    font-size: 20px !important;
    // font-weight: bold;
    padding: 5px 15px !important;
    color: $primary-grey-color !important;
    // border: 1px solid $light-grey-color;
    // border-radius: 5px;

    .active-dot {
      display: inline-block;
      width: 5px;
      height: 5px;
      vertical-align: 10px;
      pointer-events: none;
      border-radius: 50%;
      background-color: #3cb46e;
      opacity: 0;
    }

    &:hover {
      color: $primary-black-color !important;
      background-image: none !important;
    }
    &.active {
      color: $primary-black-color !important;
      background-image: none !important;
      .active-dot {
        opacity: 1;
      }
      // border-color: $primary-black-color;
    }



  }

}
