$positive-trend-color: #1C9973;
$negative-trend-color: #C74855;

.chart {
  margin-bottom: 25px;

  .header {
    display: flex;
    font-size: 12px;
    position: relative;

    .metric-tooltip {
      display: none;
      position: absolute;
      width: 100%;
      bottom: 25px;
      padding: 12px;
      background-color: $primary-black-color;
      color: $light-grey-color;
      border-radius: 3px;
      font-size: 14px;
      font-weight: 100;

      &.display {
        display: flex;
      }

      .arrow {
        display: inherit;
        position: absolute;
        bottom: -6px;
        left: 25px;
        width: 0;
        height: 0;
        border-right: 8px solid transparent;
        border-left: 8px solid transparent;
        border-top: 8px solid #2D2D2D;
      }
    }

    .metric-info {
      cursor: pointer;
    }

    .title {
      text-transform: uppercase;
      margin: 0px 12px;
    }

    .units {
      font-style: italic;
      color: $primary-grey-color;
    }
  }

  .abs-change text {
    font-size: 12px;
    &.positive {
      fill: $positive-trend-color;
    }

    &.negative {
      fill: $negative-trend-color;
    }
  }

  .brush .selection {
    fill-opacity: 0.1;
    stroke: none;
  }
}

.line {
  fill: none;
  stroke-width: 2;
}

.tick {
  line {
    stroke: #e9ecef;
    stroke-width: 1;
  }
  text {
    fill: $primary-grey-color;
    font: 10px $font-stack;
  }
}

.brush-tick {
  text {
    fill: $primary-black-color;
    font: 10px $font-stack;
    font-weight: bold;
  }

  &.mask {
    fill: #ffffff;
    stroke: none;
  }
}

.x.axis {
  .domain {
    display: none;
  }
  .tick line {
    display: none;
  }
}

.trendline {
  stroke: $primary-black-color;
  stroke-width: 1;
  stroke-dasharray: 2, 2;
}

.chart:nth-child(1n) {
  .line, .dot {
    stroke: $chart-color-dark-blue;
  }
}

.chart:nth-child(2n) {
  .line, .dot {
    stroke: $chart-color-light-blue;
  }
}

.chart:nth-child(3n) {
  .line, .dot {
    stroke: $chart-color-red;
  }
}

.chart:nth-child(4n) {
  .line, .dot {
    stroke: $chart-color-orange;
  }
}

.chart:nth-child(5n) {
  .line, .dot {
    stroke: $chart-color-purple;
  }
}

.chart:nth-child(6n) {
  .line, .dot {
    stroke: $chart-color-green;
  }
}

.chart:nth-child(7n) {
  .line, .dot {
    stroke: $chart-color-yellow;
  }
}

.chart:nth-child(8n) {
  .line, .dot {
    stroke: $chart-color-medium-blue;
  }
}
