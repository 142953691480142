.widgets {
  display: none;
  &.revealed {
    display: block;
  }
}

.widget-button {
  padding: 8px;
  font-size: 14px;
  color: $primary-grey-color;
  background-color: $light-grey-color;

  &:hover {
    cursor: pointer;
    color: $primary-black-color;
  }
}

.legend-expander {
  position: relative;

  .legend-header {
    height: 30px;
    background-color: $light-grey-color;
    display: flex;
    align-items: center;

    .title {
      font-weight: bold;
    }
  }

  #legendDiv {
    max-height: calc(100vh - 150px)
  }
}



#search {
  .esri-search__sources-button {
    display: flex;
  }
  .esri-search__submit-button {
    border: none;
  }
}

.esri-layer-list {
  .esri-layer-list__item {
    background-color: inherit;
    border-bottom: none;
  }
  .esri-layer-list__item-container {
    padding: 12px 12px 12px 12px;
  }
  .esri-layer-list__item-title {
    font-weight: 700;
  }
  .esri-layer-list__item-actions-menu-item {
    display: none;
  }
  .esri-layer-list-panel {
    margin-left: 25px;
    margin-top: 0px;
  }
  .esri-legend__service {
    padding-bottom: 0px;
  }
  .esri-legend__layer-caption {
    display: none;
  }

  #trend-key {

    padding-top: 5px;

    ul {
      list-style-type: none;
      padding: 0px;
      margin-left: 15px;

      li {
        display: flex;
        align-items: center;
        font-size: 12px;
      }
    }

    .stop {
      width: 18px;
      height: 18px;
      display: inline-block;
      margin-right: 10px;

      &.one {
        background-color: #2B83BA;
      }
      &.two {
        background-color: #ABDDA4;
      }
      &.three {
        background-color: #FFFFBF;
      }
      &.four {
        background-color: #FDAE61;
      }
      &.five {
        background-color: #D7191C;
      }
    }
  }
}

#zoom-hint.esri-component {
  color: $light-grey-color !important;
  padding-bottom: 10px;
  opacity: 0;
  display: flex;
  align-items: center;
  border: none;
  box-shadow: none;
  @include transition(opacity .35s ease);

  img {
    padding-right: 5px;
  }

  &.revealed {
    opacity: 1;
  }
}
