span.internal-link,
a {
  color: $accent-color;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: $primary-black-color;
    text-decoration: none;
  }
}

::selection {
  background: rgba(64, 217, 172, 0.5);
}

.header-section,
.body-section,
.footer-section {
  padding: 60px 0px;

  .container {
    padding-right: 30px;
    padding-left: 30px;
  }
}

.header-section {
  position: relative;
  z-index: 10;
  background-color: #ffffff;

  .row {
    display: flex;
    align-items: center;
  }

  .hero-content {

    padding-bottom: 40px;
    margin: 0 auto;
    text-align: center;
    max-width: 900px;

    h1 {
      font-size: 52px;
      font-weight: bold;
      margin-top: 0px;
      margin-bottom: 14px;
    }

    p {
      font-size: 20px;
      font-weight: 100;
      margin-bottom: 20px;
    }

  }

}

.body-section {
  background-color: #ffffff;

  .section {
    margin-bottom: 50px;
  }

  .teaser-title {
    text-align: center;

    .icon {
      max-width: 35px;
      min-height: 40px;
      opacity: 0.5;
    }
  }

  h2 {
    font-weight: bold;
    margin-bottom: 20px;
  }

  p {
    font-size: 18px;
    margin-bottom: 25px;
    line-height: 22px;
    color: $primary-grey-color;
    font-weight: 100;
  }

  ul {
    font-size: 18px;
    margin-bottom: 25px;
    line-height: 22px;
    color: $primary-grey-color;
    font-weight: 100;
  }

  table {
    border-width: 1px;
    border-color: $primary-grey-color;
    border-style: solid;
    width: 100%;
  }

  td, th {
    font-size: 14px;
    vertical-align: top;
    padding: 4px;
    border-width: 1px;
    border-color: $primary-grey-color;
    border-style: solid;
  }

  tr:first-child td, th {
    // white-space: nowrap;
    padding-right: 20px;
  }

  .teaser {
    padding-left: 0px;
    padding-right: 20px
  }
}

.footer-section {
  background-color: $light-grey-color;
  padding-bottom: 0px;
  color: $primary-grey-color;
  font-size: 12px;

  div {
    margin-bottom: 10px;
  }

  .rec-citation {
    text-align: center;
    h3 {
      margin-bottom: 20px;
      color: $primary-black-color;
    }
    p {
      font-size: 12px;
      color: $primary-grey-color;
    }
  }

  .partner-logos {
    a {
      display: inherit;
      vertical-align: middle;

      img {
        max-width: 150px;
        margin: 0 auto;
        display: block;
        vertical-align: middle;
      }
    }
  }

  // .copyright {
  //   span.internal-link,
  //   a {
  //     color: $primary-grey-color;
  //     &:hover {
  //       color: $accent-color;
  //     }
  //   }
  // }

}

.video {

  .browser-bar {
    height: 10px;
    background-color: #dddddd;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    display: flex;
    align-items: center;

    .faux-button {
      border-radius: 50%;
      background-color: #ffffff;
      display: inline-block;
      margin-left: 3px;
      height: 5px;
      width: 5px;
    }
  }

  video {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    max-width: 100%;
    box-shadow: 2px 16px 41px 15px #ccc;
  }
}

button {
  border: 1px solid $primary-black-color;
  color: $primary-black-color;
  background-color: #ffffff;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 100;
  padding: 7px 16px 5px 16px;

  &:hover {
    color: #ffffff;
    background-color: #2D2D2D;
  }
}

.graphic {
  margin-bottom: 35px;
  img {
    max-width: 100%;
  }
}

.table {
  margin-bottom: 45px;
  th {
    white-space: nowrap;
  }
  .thead-dark th {
    background-color: $primary-black-color;
    border-color: $primary-black-color;
  }

  &.ndvi {
    max-width: 70%;
    margin: 0 auto;
    font-size: 16px;
  }
}

.caption {
  margin-top: 10px;
  padding: 8px 0px;
  font-size: 16px;
  font-weight: 100;
  color: $primary-grey-color;

  .title {
    color: $primary-black-color;
    font-weight: bold;
    text-transform: uppercase;
    padding-right: 10px;
  }
}

.reference {
  padding-left: 22px;
  text-indent: -22px;
}


.home-view {
  .hero-content {
    text-align: left;
  }
}

.chart-help-view {

  .section {
    align-items: center;
    margin-bottom: 60px;
  }

  .visual {
    video, img {
      max-width: 100%;
      box-shadow: 2px 16px 41px 15px #ccc;
    }
  }
}

.dark-blue {
  color: $chart-color-dark-blue;
}
.light-blue {
  color: $chart-color-light-blue;
}
.red {
  color: $chart-color-red;
}
.orange {
  color: $chart-color-orange;
}
